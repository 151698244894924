<script lang="ts">
	import { type ServiceCookie, TrackingTechnology } from '$lib/cookieconsent/manager.store';
	import ChevronUp from '$lib/cookieconsent/ChevronUp.svelte';

	export let disclosureData: ServiceCookie[];
	let isExpanded = false;
</script>

<div>
	<div class="flex items-center text-sm mb-2">
		Gespeicherte Informationen
		<button
			on:click={() => (isExpanded = !isExpanded)}
			class="transition transform duration-200{isExpanded ? ' rotate-180' : ''}"
		>
			<ChevronUp class="w-4 h-4" />
		</button>
	</div>
	{#if isExpanded}
		{#each disclosureData as cookie}
			<div class="border border-black p-2 flex flex-col gap-2">
				<h6 class="font-bold truncate text-sm">{cookie.name}</h6>
				<p class="text-xs">{cookie.description}</p>
				<div class="grid grid-cols-2 w-1/2 text-xs gap-1">
					<div>Typ</div>
					<div>{TrackingTechnology[cookie.type]}</div>
					<div>Dauer</div>
					<div>{cookie.duration}</div>
					<div>Domain</div>
					<div>{cookie.domain}</div>
				</div>
			</div>
		{/each}
	{/if}
</div>
