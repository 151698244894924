import {
	CookieCategory,
	TrackingTechnology,
	type UsedService
} from '$lib/cookieconsent/manager.store';

const googleTag: UsedService = {
	id: '3596557455e4ebfe',
	name: 'Google Tag Manager',
	category: CookieCategory.Functional,
	description:
		'Dies ist ein Tag-Management-System. Über den Google Tag Manager können Tags zentral über eine Benutzeroberfläche eingebunden werden. Tags sind kleine Codeabschnitte, die Aktivitäten nachverfolgen können. Über den Google Tag Manager werden Scriptcodes anderer Tools eingebunden. Der Tag Manager ermöglicht es zu steuern, wann ein bestimmtes Tag ausgelöst wird.',
	companyAddress: `Google Ireland Limited
Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland`,
	dataProtectionOfficer: 'https://support.google.com/policies/contact/general_privacy_form',
	dataUse: ['Tag-Verwaltung'],
	usedTechnologies: [TrackingTechnology.Tag],
	collectedData: ['Aggregierte Daten über die Tag-Auslösung'],
	legalBasis: ['Art. 6 Abs. 1 S. 1 lit. a DSGVO'],
	processingPlace: ['Europäische Union'],
	retentionPeriod: ['Die Daten werden 14 Tagen nach Abruf gelöscht.'],
	transferToThirdCountries: ['Vereinigte Staaten von Amerika', 'Singapur', 'Taiwan', 'Chile'],
	privacyUrl: 'https://policies.google.com/privacy?hl=en',
	storageInformation: [],
	initialState: true,
	cookieData: []
};
const cookieBanner: UsedService = {
	id: '9dfcca9391385353',
	name: 'Cookie Banner',
	category: CookieCategory.Essential,
	description: 'Dieser Cookie Banner.',
	companyAddress: '',
	dataProtectionOfficer: 'https://enlow.de/privacy',
	dataUse: ['Cookie Einstellungen'],
	usedTechnologies: [TrackingTechnology.Cookies],
	collectedData: ['Speichert die Cookie Präferenzen des Nutzers'],
	legalBasis: ['Art. 6 Abs. 1 S. 1 lit. a DSGVO'],
	processingPlace: ['Europäische Union'],
	retentionPeriod: ['Die Daten werden 1 Jahr nach Abruf gelöscht.'],
	transferToThirdCountries: [],
	privacyUrl: 'https://enlow.de/privacy',
	storageInformation: [],
	initialState: true,
	cookieData: [
		{
			name: 'gdpr-settings',
			type: TrackingTechnology.Cookies,
			duration: '1 Jahr',
			description: 'Speichert die Präferenzen des Nutzers',
			domain: 'enlow.de'
		}
	]
};
const postHog: UsedService = {
	id: '8b58e103fbee51d7',
	name: 'PostHog',
	category: CookieCategory.Functional,
	description:
		'Dieser Dienst ermöglicht es dem Benutzer, den Verkehr und das Engagement auf seinen Websites und mobilen Apps mithilfe anpassbarer Berichte zu messen.',
	companyAddress: 'PostHog Inc.',
	dataProtectionOfficer: 'privacy@posthog.com',
	dataUse: ['Marketing', 'Analyse'],
	usedTechnologies: [TrackingTechnology.Cookies],
	collectedData: [
		'Kontodaten',
		'Anonymisierte IP-Adresse',
		'Absprungraten',
		'Browser-Informationen',
		'Klickpfad',
		'Datum und Uhrzeit des Besuchs',
		'Geräteinformationen',
		'Downloads',
		'Besuchsdauer',
		'Standort-Informationen',
		'Internetdienstanbieter',
		'Mausbewegungen',
		'Bildschirmauflösung',
		'Verhaltensdaten'
	],
	legalBasis: ['Art. 6 Abs. 1 S. 1 lit. a DSGVO'],
	processingPlace: ['Europäische Union'],
	retentionPeriod: ['Die Daten werden nach 1 Jahr nach Abruf gelöscht.'],
	transferToThirdCountries: [],
	privacyUrl: 'https://posthog.com/privacy',
	storageInformation: [],
	initialState: true,
	cookieData: [
		{
			name: 'ph_phc_3sH5i0DbfifmGRMq02wrsp1ferQir9l5dqysEH1RNhI_posthog',
			type: TrackingTechnology.Cookies,
			duration: '1 Jahr',
			description: 'Speichert eine Eindeutige ID',
			domain: 'enlow.de'
		}
	]
};
const facebookPixel: UsedService = {
	id: 'a2da5358f509e256',
	name: 'Facebook Pixel',
	category: CookieCategory.Marketing,
	description:
		'Dies ist eine Tracking -Technologie, die von Facebook angeboten und von anderen Facebook-Diensten verwendet wird. Es wird verwendet, um Interaktionen von Besuchern mit Websites ("Ereignisse") zu verfolgen, nachdem sie auf eine auf Facebook oder anderen von Meta bereitgestellten Diensten geschaltete Anzeige geklickt haben ("Conversion").',
	companyAddress: 'Meta Platforms Ireland Ltd. 4 Grand Canal Square, Grand Canal Harbour, Dublin, D02, Ireland',
	dataProtectionOfficer: 'https://www.facebook.com/help/contact/540977946302970',
	dataUse: [
		'Analyse',
		'Marketing',
		'Retargeting',
		'Werbung',
		'Conversion Tracking',
		'Personalisierung',
	],
	usedTechnologies: [TrackingTechnology.Cookies, TrackingTechnology.TrackingPixel],
	collectedData: [
		'Angesehene Werbeanzeigen',
		'Angesehener Inhalt',
		'Geräteinformationen',
		'Geografischer Standort',
		'HTTP-Header',
		'Interaktionen mit Werbung, Services und Produkten',
		'IP-Adresse',
		'Angeklickte Elemente',
		'Marketinginformation',
		'Besuchte Seiten',
		'Pixel-ID',
		'Referrer URL',
		'Nutzungsdaten',
		'Nutzerverhalten',
		'Facebook-Cookie-Informationen',
		'Facebook Nutzer-ID',
		'Nutzungs-/Klickverhalten',
		'Browser-Informationen',
		'Gerätebestriebssystem',
		'Geräte-ID',
		'User Agent',
		'Browser-Typ',
	],
	legalBasis: ['Art. 6 Abs. 1 S. 1 lit. a DSGVO'],
	processingPlace: ['Europäische Union'],
	retentionPeriod: ['Die auf den Websites erfassten Interaktionen der Nutzer werden nicht länger als zwei Jahre gespeichert. Die Daten werden jedoch gelöscht, sobald sie für die Zwecke der Verarbeitung nicht mehr benötigt werden.'],
	transferToThirdCountries: [
		'Singapur',
		'Vereinigte Staaten von Amerika',
		'Weltweit',
	],
	privacyUrl: 'https://www.facebook.com/privacy/explanation',
	storageInformation: [
		'Höchstgrenze für die Speicherung von Cookies: 1 Jahr',
		'Nicht-Cookie-Speicherung: nein',
	],
	initialState: false,
	cookieData: [
		{
			name: '_fbp',
			type: TrackingTechnology.Cookies,
			duration: '3 Monate',
			description: 'Cookie from Facebook used for website analytics, ad targeting and ad measurement.',
			domain: 'facebook.com'
		}
	]
};

export const services = [cookieBanner, googleTag, postHog, facebookPixel];
