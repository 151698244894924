<script lang="ts">
	export let title: string;
	export let content: string | string[];
</script>

<div>
	<p class="text-gray-500 text-xs">{title}</p>
	<p class="text-sm break-words">
		{Array.isArray(content)
			? content.length === 0
				? '-'
				: content.join(', ')
			: content
			? content
			: '-'}
	</p>
</div>
