<script>
	import { getTranslate, T } from '@tolgee/svelte';
	import PrimaryButton from './buttons/PrimaryButton.svelte';
	import { tryEmailSubmit, FormPosition } from '$lib/form/submit';

	const t = getTranslate();

	let email = '';
	let allowsAds = true;
</script>

<footer class="py-8 md:py-8 lg:py-10 bg-accent-dark-bg x-wrapper">
	<div class="mx-auto max-w-screen-xl text-center">
		<div class="grid lg:grid-cols-3">
			<a
				aria-label="Enlow Logo"
				href="/"
				class="flex items-center mb-4 text-2xl max-w-[9rem] font-semibold lg:mb-0 text-white"
			>
				<svg
					width="163"
					height="38"
					viewBox="0 0 163 38"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M127.342 25.2503L118.839 1.36246H126.027L131.637 16.7033L137.379 1.80077H144.392L150.134 16.6156L155.701 1.36246H162.889L154.386 25.2503H146.408L140.886 11.9257L135.319 25.2503H127.342ZM100.881 6.5345C94.6134 6.5345 90.5371 11.0053 90.5371 17.3607C90.5371 23.6724 94.6134 28.187 100.881 28.187C107.149 28.187 111.225 23.7162 111.225 17.3607C111.225 11.0053 107.149 6.5345 100.881 6.5345ZM83.7872 17.3607C83.7872 7.36729 91.3699 0.48584 100.881 0.48584C110.393 0.48584 117.975 7.41112 117.975 17.3607C117.975 27.3104 110.393 34.2356 100.881 34.2356C91.3699 34.2356 83.7872 27.3542 83.7872 17.3607ZM82.5994 33.5782H61.0345V1.36246H67.7845V27.3104H82.5994V33.5782ZM27.9159 33.5782V1.36246H37.2957L48.1658 25.1188V1.36246H54.6966V33.5782H45.3168L34.4467 9.778V33.5782H27.9159ZM22.6168 33.5782H0V1.36246H22.5291V7.41112H6.74997V14.4679H21.3457V20.4289H6.74997V27.5295H22.6168V33.5782ZM125.13 31.2245V37.5142L118.84 37.4879V31.2245H125.13Z"
						fill="white"
					/>
				</svg>
			</a>
			<ul class="flex flex-wrap items-center mb-4 text-sm lg:mb-0 font-bold text-gray-400">
				<li>
					<a href="/imprint" class="mr-4 hover:underline md:mr-6">
						<T keyName="imprint" />
					</a>
				</li>
				<li>
					<a href="/privacy" class="mr-4 hover:underline md:mr-6">
						<T keyName="datasecurity" />
					</a>
				</li>
			</ul>
			<form
				method="post"
				on:submit|preventDefault={() => {
					allowsAds = tryEmailSubmit(email, FormPosition.Footer);
				}}
				class="hidden md:flex w-full max-w-sm lg:ml-auto"
			>
				<div class="relative w-full">
					<label for="emailDesktop" class="hidden mb-2 text-sm font-medium text-gray-300">
						<T keyName="footer_email_address_label" />
					</label>
					<input
						type="email"
						id="emailDesktop"
						name="emailDesktop"
						bind:value={email}
						class="block p-3 w-full text-sm rounded-l-lg border focus:ring-primary-500 focus:border-primary-500 bg-transparent border-white placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500"
						placeholder={$t('footer_email_placeholder')}
						required
					/>
				</div>
				<PrimaryButton type="submit" noLeftRadius>
					<T keyName="footer_button_preorder" />
				</PrimaryButton>
			</form>
			<form
				method="post"
				on:submit|preventDefault={() => {
					allowsAds = tryEmailSubmit(email, FormPosition.Footer);
				}}
				class="flex md:hidden flex-col w-full max-w-sm gap-3"
			>
				<div class="relative w-full">
					<label for="emailMobile" class="hidden mb-2 text-sm font-medium text-gray-300">
						<T keyName="footer_email_address_label" />
					</label>
					<input
						type="email"
						id="emailMobile"
						name="emailMobile"
						bind:value={email}
						class="block p-3 w-full text-sm rounded-lg border focus:ring-primary-500 focus:border-primary-500 bg-transparent border-white placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500"
						placeholder={$t('footer_email_placeholder')}
						required
					/>
				</div>
				<PrimaryButton size="base" type="submit">
					<T keyName="footer_button_preorder" /></PrimaryButton
				>
			</form>
		</div>
		<div class="flex justify-end mt-4">
			<p class="text-xs text-gray-500 w-72">
				Mit der Anmeldung zum Newsletter akzeptierst du die <a href="/privacy" class="underline"
					>Datenschutzbestimmungen</a
				>
			</p>
		</div>
		<hr class="my-6 sm:mx-auto border-gray-700 lg:my-8" />
		<div class="sm:items-center sm:justify-between sm:flex">
			<span class="text-left md:text-center block text-sm text-gray-400">
				{@html $t('footer_rights_note')}
			</span>
			<div class="flex sm:justify-center mt-4 space-x-6 sm:mt-0">
				<a
					aria-label="Linkedin"
					href="https://www.linkedin.com/company/enlow/about/"
					class="hover:text-white text-gray-400"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
						fill="currentColor"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						><path
							d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.339 18.337H5.667v-8.59h2.672v8.59zM7.003 8.574a1.548 1.548 0 1 1 0-3.096 1.548 1.548 0 0 1 0 3.096zm11.335 9.763h-2.669V14.16c0-.996-.018-2.277-1.388-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248h-2.667v-8.59h2.56v1.174h.037c.355-.675 1.227-1.387 2.524-1.387 2.704 0 3.203 1.778 3.203 4.092v4.71z"
						/></svg
					>
				</a>
				<a
					aria-label="Instagram"
					href="https://www.instagram.com/enlow_de/"
					class="hover:text-white text-gray-400"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
						fill="currentColor"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						><path
							d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"
						/><circle cx="16.806" cy="7.207" r="1.078" /><path
							d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"
						/></svg
					>
				</a>
			</div>
		</div>
	</div>
</footer>
