<script lang="ts">
	import {
		gtagEnabled,
		postHogEnabled,
		facebookPixelEnabled,
		cookieManager
	} from '$lib/stores/tracking.store';
	import posthog from 'posthog-js';
	import { browser } from '$app/environment';
	import { get } from 'svelte/store';
	import { PUBLIC_ENVIRONMENT } from '$env/static/public';
	import { wasConverted } from '$lib/form/submit';

	let lastGtagState = false;
	gtagEnabled.subscribe((enabled) => {
		if (!browser) {
			return;
		}
		const cm = get(cookieManager);
		if (!enabled && lastGtagState && !cm.isOpen()) {
			window.location.reload();
		}
		lastGtagState = enabled;
	});
	let lastFacebookState = false;
	facebookPixelEnabled.subscribe((enabled) => {
		if (!browser) {
			return;
		}
		const cm = get(cookieManager);
		if (!enabled && lastFacebookState && !cm.isOpen()) {
			window.location.reload();
		}
		lastFacebookState = enabled;
	});

	let NewsletterDialog;
	let flag;
	let wasCreated = false;
	postHogEnabled.subscribe((enabled) => {
		if (!browser) {
			return;
		}
		posthog.init('phc_3sH5i0DbfifmGRMq02wrsp1ferQir9l5dqysEH1RNhI', {
			api_host: 'https://eu.posthog.com'
		});
		if (enabled) {
			posthog.opt_in_capturing();
			const popupDuration = posthog.getFeatureFlag('show_newsletter_popup');
			flag = popupDuration;
			if (popupDuration && !wasCreated) {
				const duration = (() => {
					switch (popupDuration) {
						case 'long':
							return 40000;
						case 'short':
							return 20000;
						default:
							return 30000;
					}
				})();
				wasCreated = true;
				const current = Date.now();
				import('$lib/NewsletterDialog.svelte').then((component) => {
					const now = Date.now();
					const shrinkedDuration = duration - (now - current);
					setTimeout(() => {
						if ($wasConverted) {
							return;
						}
						posthog.capture('show_newsletter_dialog');
						NewsletterDialog = component.default;
					}, shrinkedDuration);
				});
			}
		} else {
			posthog.opt_out_capturing();
		}
	});
</script>

<svelte:head>
	{#if browser}
		{#if $gtagEnabled}
			<!-- Google Tag Manager -->
			<script>
				(function (w, d, s, l, i) {
					w[l] = w[l] || [];
					w[l].push({
						'gtm.start': new Date().getTime(),
						event: 'gtm.js'
					});
					var f = d.getElementsByTagName(s)[0],
						j = d.createElement(s),
						dl = l != 'dataLayer' ? '&l=' + l : '';
					j.async = true;
					j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
					f.parentNode.insertBefore(j, f);
				})(window, document, 'script', 'dataLayer', 'GTM-K7XPT98');
			</script>
			<!-- End Google Tag Manager -->
		{/if}

		{#if $facebookPixelEnabled}
			<!-- Meta Pixel Code -->
			<script>
				!(function (f, b, e, v, n, t, s) {
					if (f.fbq) return;
					n = f.fbq = function () {
						n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
					};
					if (!f._fbq) f._fbq = n;
					n.push = n;
					n.loaded = !0;
					n.version = '2.0';
					n.queue = [];
					t = b.createElement(e);
					t.async = !0;
					t.src = v;
					s = b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t, s);
				})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
				fbq('init', '566809624898398');
				fbq('track', 'PageView');
			</script>
			<!-- End Meta Pixel Code -->
		{/if}
	{/if}
</svelte:head>
{#if browser}
	{#if $gtagEnabled}
		<!-- Google Tag Manager (noscript) -->
		<noscript>
			<iframe
				title=""
				src="https://www.googletagmanager.com/ns.html?id=GTM-K7XPT98"
				height="0"
				width="0"
				style="display:none;visibility:hidden"
			/>
		</noscript>
		<!-- End Google Tag Manager (noscript) -->
	{/if}
	{#if $facebookPixelEnabled}
		<!-- Meta Pixel Code -->
		<noscript
			><img
				alt=""
				height="1"
				width="1"
				style="display: none"
				src="https://www.facebook.com/tr?id=566809624898398&ev=PageView&noscript=1"
			/></noscript
		>
		<!-- End Meta Pixel Code -->
	{/if}
{/if}
{#if PUBLIC_ENVIRONMENT !== 'prd'}
	<div class="fixed z-50 bg-red-600 p-2 top-0 left-0">
		show_newsletter_popup: "{flag}"
	</div>
{/if}
<svelte:component this={NewsletterDialog} />
