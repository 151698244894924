<script lang="ts">
	import '../app.css';
	import Footer from '../lib/Footer.svelte';
	import Navbar from '../lib/Navbar.svelte';
	import { TolgeeProvider } from '@tolgee/svelte';
	import tolgeeDe from '../i18n/de.json';
	import Tracking from '$lib/Tracking.svelte';
	import CookieBanner from '$lib/cookieconsent/CookieBanner.svelte';
	import { cookieManager } from '$lib/stores/tracking.store';
	import { saveEnergyStore } from '$lib/stores/save-energy.store';
	import { BrowserTracing } from '@sentry/tracing';
	import * as Sentry from '@sentry/svelte';
	import { PUBLIC_ENVIRONMENT, PUBLIC_SENTRY_DSN } from '$env/static/public';
	import posthog from 'posthog-js';
	import { browser, dev } from '$app/environment';
	import { onMount } from 'svelte';
	import type { Metric } from 'web-vitals';
	import { fade } from 'svelte/transition';
	import { TolgeeConfig } from '@tolgee/core';

	const tolgeeConfig = {
		preloadFallback: true,
		apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
		apiKey: PUBLIC_ENVIRONMENT === 'prd' ? undefined : import.meta.env.VITE_TOLGEE_API_KEY,
		availableLanguages: ['de'],
		defaultLanguage: 'de',
		fallbackLanguage: 'de',
		ui: PUBLIC_ENVIRONMENT !== 'prd' ? import('@tolgee/ui') : undefined,
		staticData: {
			de: tolgeeDe
		}
	} as TolgeeConfig;

	if (PUBLIC_SENTRY_DSN && !dev) {
		Sentry.init({
			dsn: PUBLIC_SENTRY_DSN,
			integrations: [new BrowserTracing()],
			tracesSampleRate: 1
		});
	}
	let NewsletterDialog;

	onMount(() => {
		if (browser) {
			const report = (metric: Metric) => {
				posthog.capture('performance', {
					name: metric.name,
					value: metric.value
				});
			};
			import('web-vitals').then(({ onCLS, onTTFB, onFCP, onLCP, onFID, onINP }) => {
				onFCP(report);
				onCLS(report);
				onTTFB(report);
				onLCP(report);
				onFID(report);
				onINP(report);
			});
		}
	});
</script>

<TolgeeProvider config={tolgeeConfig}>
	<div slot="loading-fallback">Loading...</div>
	<Navbar />
	<slot />
	<Footer />
	<CookieBanner
		title="Privatsphäre-Einstellungen"
		description="Dieses Tool hilft dir, verschiedene Tags / Tracker / Analyse-Tools auf dieser Webseite auszuwählen und zu deaktivieren."
		manager={cookieManager}
		privacyUrl="/privacy"
	/>
	<Tracking />
</TolgeeProvider>
{#if $saveEnergyStore}
	<div
		transition:fade
		class="fixed w-full h-full bg-opacity-75 bg-black z-40 left-0 top-0 pointer-events-none"
	/>
{/if}
