<script lang="ts">
	import {
		ICookieManager,
		type UsedService,
		CookieCategory,
		TrackingTechnology
	} from '$lib/cookieconsent/manager.store';
	import CookieInfo from '$lib/cookieconsent/CookieInfo.svelte';
	import ChevronUp from '$lib/cookieconsent/ChevronUp.svelte';
	import CookieDisclosure from '$lib/cookieconsent/CookieDisclosure.svelte';

	export let service: UsedService;
	export let manager: ICookieManager;
	let isExpanded = false;
</script>

<div class="complex-category">
	<label class="flex justify-between" for="cookie-category-{service.id}">
		<div class="flex flex-col w-5/6">
			<h6 class="font-bold">
				{service.name}
			</h6>
			<p class="text-sm">
				{CookieCategory[service.category]}
			</p>
		</div>
		<div class="flex items-center gap-2">
			<input
				on:change={(e) => {
					const to = e.target.checked;
					if (to) {
						$manager.acceptService(service.id);
					} else {
						$manager.denyService(service.id);
					}
				}}
				checked={$manager.hasAcceptedService(service.id)}
				disabled={service.category === CookieCategory.Essential}
				class="w-5 h-5"
				type="checkbox"
				id="cookie-category-{service.id}"
				name="cookie-category-{service.id}"
			/>
			<button
				on:click={() => (isExpanded = !isExpanded)}
				class="transition transform duration-200{isExpanded ? ' rotate-180' : ''}"
			>
				<ChevronUp />
			</button>
		</div>
	</label>
	{#if isExpanded}
		<hr />
		<div class="py-2 flex flex-col gap-4">
			<CookieInfo title="Beschreibung des Services" content={service.description} />
			<CookieInfo title="Verarbeitendes Unternehmen" content={service.companyAddress} />
			<CookieInfo
				title="Datenschutzbeauftragter des verarbeitenden Unternehmens"
				content={service.dataProtectionOfficer}
			/>
			<CookieInfo title="Zweck der Daten" content={service.dataUse} />
			<CookieInfo
				title="Genutzte Technologien"
				content={service.usedTechnologies.map((tech) => TrackingTechnology[tech])}
			/>
			<CookieInfo title="Gesammelte Daten" content={service.collectedData} />
			<CookieInfo title="Rechtliche Grundlage" content={service.legalBasis} />
			<CookieInfo title="Ort der Verarbeitung" content={service.processingPlace} />
			<CookieInfo title="Aufbewahrungsdauer" content={service.retentionPeriod} />
			<CookieInfo title="Datenschutzerklärung des Anbieters" content={service.privacyUrl} />
			<CookieInfo title="Speicherinformationen" content={service.storageInformation} />
			<CookieInfo title="Weitergabe an Drittländer" content={service.transferToThirdCountries} />
		</div>
		<CookieDisclosure disclosureData={service.cookieData} />
	{/if}
</div>

<style>
	.complex-category {
		padding: 0.5rem;
		border: 1px solid black;
		border-radius: 4px;
		margin: 1rem 0;
	}
</style>
