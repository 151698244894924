<script lang="ts">
	import PrimaryButton from './buttons/PrimaryButton.svelte';
	import { T } from '@tolgee/svelte';
	import { getCDNUrlFromFilename } from './utils';
	import { page } from '$app/stores';
	import { goto } from '$app/navigation';
</script>

<nav class="bg-white border-gray-200 flex items-center rounded-b x-wrapper fixed w-full h-16 z-40">
	<div class="container flex flex-wrap justify-between items-center mx-auto">
		<a href="/" class="flex items-center">
			<img src={getCDNUrlFromFilename('enlow_black.svg')} class="mr-3 h-6 sm:h-7" alt="Logo" />
		</a>
		<div class="flex md:order-2">
			<a
				href="/#contact"
				class="hidden md:block text-black text-sm font-bold rounded-lg px-5 py-2.5 text-center mr-3 md:mr-0"
			>
				<T keyName="header_button_contact" />
			</a>
			<PrimaryButton
				on:click={() => {
					if ($page.url.pathname === '/') {
						window.scrollTo({ top: 0 });
					} else {
						goto('/');
					}
				}}
			>
				<T keyName="header_button_preorder" />
			</PrimaryButton>
		</div>
	</div>
</nav>
