<script lang="ts">
	import {
		createCookieManager,
		type ICookieManager,
		type UsedService,
		CookieCategory,
		getCategoryDescription
	} from '$lib/cookieconsent/manager.store';
	import type { Readable } from 'svelte/store';
	import CookieServiceEntry from '$lib/cookieconsent/CookieServiceEntry.svelte';
	import ChevronUp from '$lib/cookieconsent/ChevronUp.svelte';
	import { scale, fly } from 'svelte/transition';

	type Tab = 'categories' | 'services';

	export let manager: Readable<ICookieManager> | null = null;

	export let title: string;
	export let description: string;
	export let privacyUrl: string;
	/**
	 * This property will be ignored if you supply your own manager
	 */
	export let services: UsedService[] = [];
	/**
	 * This property will be ignored if you supply your own manager
	 */
	export let cookieName = 'gdpr-settings';
	const _manager = manager ?? createCookieManager(services, cookieName);
	let complexView = false;
	let activeTab: Tab = 'categories';
</script>

{#if $_manager.isOpen()}
	<div
		transition:fly={{ y: 100 }}
		class="consent-banner shadow-xl border border-2 border-accent-dark-bg {complexView
			? 'xl:max-w-[50%]'
			: 'lg:max-w-[35%] sm:max-w-full md:max-w-full'}"
	>
		<div class="flex justify-between">
			<h5 class="font-bold">
				{title}
			</h5>
			<button on:click={() => (complexView = !complexView)} class="text-xs pl-2 flex items-center">
				<div>{complexView ? 'Weniger' : 'Mehr'} anzeigen</div>
				<ChevronUp
					class="w-6 h-6 transition transform duration-200{complexView ? ' rotate-180' : ''}"
				/>
			</button>
		</div>
		<div class="flex items-center">
			{#if complexView}
				<p>{description}</p>
			{/if}
		</div>
		<a class="text-xs underline" href={privacyUrl}>Datenschutzerklärung</a>
		{#if complexView}
			<div transition:fly class="flex text-sm mt-2">
				<button on:click={() => (activeTab = 'categories')} class="w-full text-center">
					<div
						class="h-1 {activeTab === 'categories' ? 'bg-accent-primary' : 'bg-accent-dark-bg'}"
					/>
					Kategorien
				</button>
				<button on:click={() => (activeTab = 'services')} class="w-full text-center">
					<div
						class="h-1 {activeTab === 'services'
							? 'bg-accent-primary'
							: 'bg-accent-dark-bg'} bg-accent-dark-bg"
					/>
					Services
				</button>
			</div>
			<div transition:fly={{ y: 100 }} class="py-4 max-h-[50vh] overflow-y-auto">
				{#if activeTab === 'categories'}
					{#each $_manager.allCategories as category}
						<label class="complex-category" for="cookie-category-{category}">
							<div class="flex flex-col place-self-start max-w-md">
								<h6 class="font-bold">
									{CookieCategory[category]}
								</h6>
								<p class="text-sm">
									{getCategoryDescription(category)}
								</p>
							</div>
							<input
								on:change={(e) => {
									const to = e.target.checked;
									if (to) {
										$_manager.acceptCategory(category);
									} else {
										$_manager.denyCategory(category);
									}
								}}
								checked={$_manager.hasAcceptedCategory(category)}
								disabled={category === CookieCategory.Essential}
								class="w-5 h-5"
								type="checkbox"
								id="cookie-category-{category}"
								name="cookie-category-{category}"
							/>
						</label>
					{/each}
				{:else}
					{#each $_manager.allServices as service}
						<CookieServiceEntry manager={_manager} {service} />
					{/each}
				{/if}
			</div>
		{:else}
			<div class="quick-consent">
				{#each $_manager.allCategories as category}
					<label class="flex gap-2 flex-wrap" for="cookie-category-{category}">
						<div>{CookieCategory[category]}</div>
						<input
							on:change={(e) => {
								const to = e.target.checked;
								if (to) {
									$_manager.acceptCategory(category);
								} else {
									$_manager.denyCategory(category);
								}
							}}
							checked={$_manager.hasAcceptedCategory(category)}
							disabled={category === CookieCategory.Essential}
							type="checkbox"
							id="cookie-category-{category}"
							name="cookie-category-{category}"
						/>
					</label>
				{/each}
			</div>
		{/if}
		<div class="button-bar grid md:grid-cols-3">
			<button
				on:click={() => {
					$_manager.denyAll();
				}}
				>Alle ablehnen
			</button>
			<button
				on:click={() => {
					$_manager.save();
				}}
				>Auswahl speichern
			</button>
			<button
				on:click={() => {
					$_manager.acceptAll();
				}}
				>Alle akzeptieren
			</button>
		</div>
	</div>
{:else}
	<button transition:scale on:click={() => $_manager.open()} class="consent-bubble">
		<span class="sr-only">Cookie Banner öffnen</span>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			stroke-width="1.5"
			stroke="currentColor"
			class="consent-bubble-svg"
		>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
			/>
		</svg>
	</button>
{/if}

<style>
	.complex-category {
		padding: 0.5rem;
		border: 1px solid black;
		border-radius: 4px;
		display: grid;
		place-items: center;
		grid-template-columns: 1fr 1.25rem;
		margin: 1rem 0;
		@apply justify-between;
	}

	.consent-bubble {
		z-index: 400;
		position: fixed;
		right: 1rem;
		bottom: 1rem;
		border-radius: 9999px;
		background-color: #bdff00;
		padding: 0.75rem;
		box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	}

	.consent-bubble-svg {
		color: #000036;
		height: 2.5rem;
		width: 2.5rem;
	}

	.consent-banner {
		z-index: 400;
		position: fixed;
		right: 0;
		bottom: 0;
		border-radius: 15px;
		background-color: white;
		padding: 1rem;
		margin: 1rem;
	}

	.button-bar > button {
		background-color: #bdff00;
		padding: 0.5rem 0.75rem;
		border-radius: 10px;
		@apply font-bold;
	}

	.quick-consent {
		display: flex;
		gap: 1rem;
		padding-top: 0.25rem;
		padding-bottom: 1rem;
	}

	.button-bar {
		gap: 0.5rem;
	}

	h5 {
		font-size: 1.5rem;
		margin: 0;
	}
</style>
