import Cookies from 'js-cookie';
import { readable } from 'svelte/store';
import { browser } from '$app/environment';

export enum CookieCategory {
	Essential,
	Social,
	Personalization,
	Marketing,
	Statistics,
	Functional
}

export const getCategoryDescription: (category: CookieCategory) => string = (
	category: CookieCategory
) => {
	switch (category) {
		case CookieCategory.Essential:
			return 'Diese Technologien sind erforderlich, um die Kernfunktionalität der Website zu aktivieren.';
		case CookieCategory.Social:
		case CookieCategory.Personalization:
		case CookieCategory.Marketing:
		case CookieCategory.Statistics:
		case CookieCategory.Functional:
			return 'Diese Technologien ermöglichen es uns, die Nutzung der Website zu analysieren, um die Leistung zu messen und zu verbessern.';
	}
};

export enum TrackingTechnology {
	Cookies,
	TrackingPixel,
	Tag
}

export interface ServiceCookie {
	name: string;
	type: TrackingTechnology;
	duration: string;
	description: string;
	domain: string;
}

export interface UsedService {
	id: string;
	name: string;
	category: CookieCategory;
	description: string;
	companyAddress: string;
	dataProtectionOfficer: string;
	dataUse: string[];
	usedTechnologies: TrackingTechnology[];
	collectedData: string[];
	legalBasis: string[];
	processingPlace: string[];
	retentionPeriod: string[];
	transferToThirdCountries: string[];
	privacyUrl: string;
	storageInformation: string[];
	initialState: boolean;
	cookieData: ServiceCookie[];
}

export abstract class ICookieManager {
	abstract acceptAll(): void;

	abstract denyAll(): void;

	abstract acceptCategory(category: CookieCategory): void;

	abstract denyCategory(category: CookieCategory): void;

	abstract acceptService(id: string): void;

	abstract denyService(id: string): void;

	abstract isDone(): boolean;
	abstract isOpen(): boolean;

	abstract hasAcceptedCategory(item: CookieCategory): boolean;

	abstract hasAcceptedService(id: string): boolean;

	abstract save(): void;

	abstract open(): void;

	abstract get allServices(): UsedService[];

	get allCategories(): CookieCategory[] {
		const categories = new Set<CookieCategory>();
		for (const service of this.allServices) {
			categories.add(service.category);
		}
		return [...categories];
	}
}

interface MergeResult {
	result: UsedService[];
	hasDiff: boolean;
}

export class CookieManager extends ICookieManager {
	private _isDone = true;
	private _isOpen = false;

	constructor(
		private onUpdate: (manager: CookieManager) => void,
		private readonly services: UsedService[],
		private cookieName: string
	) {
		super();
		if (!browser) {
			return;
		}
		const cookieData = Cookies.get(cookieName);
		const mergeResult = CookieManager.mergeServices(this.services, JSON.parse(cookieData || '[]'));
		this.services = mergeResult.result;
		this._isDone = !mergeResult.hasDiff;
	}

	private static mergeServices(
		fetchedData: UsedService[],
		storedData: Pick<UsedService, 'id' | 'initialState'>[]
	): MergeResult {
		let hasDiff = false;
		const result = fetchedData.map((service) => {
			const storedCookieData = storedData.find((stored) => stored.id === service.id);
			if (storedCookieData) {
				return {
					...service,
					initialState: storedCookieData.initialState
				};
			}
			hasDiff = true;
			return service;
		});
		return {
			result,
			hasDiff
		};
	}

	private get _servicesStorable() {
		return this.services.map((service) => ({
			id: service.id,
			initialState: service.initialState
		}));
	}

	open() {
		this._isOpen = true;
		this._isDone = true;
		this.onUpdate(this);
	}

	/**
	 * Writes the cookie into the browser and signals that the cookie banner was
	 * successfully completed
	 */
	save() {
		Cookies.set(this.cookieName, JSON.stringify(this._servicesStorable), {
			expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365)
		});
		this._isDone = true;
		this._isOpen = false;
		this.onUpdate(this);
	}

	/**
	 * Whether the cookie banner has been completed
	 */
	isDone(): boolean {
		return this._isDone;
	}

	isOpen(): boolean {
		return !this._isDone || this._isOpen;
	}

	/**
	 * Accepts all cookies and closes the banner
	 */
	acceptAll(): void {
		this._setForAll(true);
	}

	/**
	 * Accepts a category of cookies without closing the banner
	 * @param category The cookie category to accept
	 */
	acceptCategory(category: CookieCategory): void {
		this._setForCategory(category, true);
	}

	/**
	 * Accepts a service by id
	 * @param id The service id to accept the cookies from
	 */
	acceptService(id: string): void {
		const serviceIndex = this.services.findIndex((service) => service.id === id);
		this._setAtIndex(serviceIndex, true);
	}

	/**
	 * Sets all cookies to not accepted except the essential category
	 */
	denyAll(): void {
		this._setForAll(false);
	}

	denyCategory(category: CookieCategory): void {
		this._setForCategory(category, false);
	}

	denyService(id: string): void {
		const serviceIndex = this.services.findIndex((service) => service.id === id);
		this._setAtIndex(serviceIndex, false);
	}

	get allServices() {
		return Array.from(this.services);
	}

	hasAcceptedCategory(item: CookieCategory): boolean {
		return this.services
			.filter((service) => service.category === item)
			.every((item) => item.initialState);
	}

	hasAcceptedService(id: string): boolean {
		return this.services.find((service) => service.id === id)?.initialState ?? false;
	}

	private _setForAll(state: boolean) {
		for (let i = 0; i < this.services.length; i++) {
			this._setAtIndex(i, state);
		}
		this.save();
	}

	private _setForCategory(category: CookieCategory, state: boolean) {
		for (let i = 0; i < this.services.length; i++) {
			const service = this.services[i];
			if (service.category === category) {
				this._setAtIndex(i, state);
			}
		}
	}

	private _setAtIndex(index: number, state: boolean) {
		if (this.services[index].category === CookieCategory.Essential) {
			return;
		}
		this.services[index].initialState = state;
		this.onUpdate(this);
	}
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const createCookieManager = (services: UsedService[], cookieName = 'gdpr-settings') =>
	readable(new CookieManager(() => {}, services, cookieName), (set) => {
		set(new CookieManager((m) => set(m), services, cookieName));
	});
