import { derived, writable } from 'svelte/store';
import { createCookieManager } from '$lib/cookieconsent/manager.store';
import { services } from '$lib/cookieconsent/services';

export const cookieManager = createCookieManager(services);

export const postHogEnabled = derived(
	cookieManager,
	($manager) => $manager.isDone() && $manager.hasAcceptedService('8b58e103fbee51d7')
);
export const gtagEnabled = derived(cookieManager, ($manager) => {
	return $manager.isDone() && $manager.hasAcceptedService('3596557455e4ebfe');
});
export const facebookPixelEnabled = derived(cookieManager, ($manager) => {
	return $manager.isDone() && $manager.hasAcceptedService('a2da5358f509e256');
});

